import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as api from "../../utils/api/apiClient";
import Loading from "../Loading";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Input, TextField } from "@mui/material";

function CreateCategory({
  data,
  isOpen,
  refreshState,
  handleClose,
  // categoryId,
  whatDoYouWant = "create",
}) {
  const [loading, setLoading] = useState(false);
  const [categoryFormData, setCategoryFormData] = useState({
    name: "",
    description: "",
    thumbnail: {},
  });
  // const [attributeName, setAttributeName] = useState("");

  useEffect(() => {
    if (data) {
      console.log(data);
      setCategoryFormData({
        name: data.name || "",
        description: data.description || "",
      });
    }
  }, [data]);

  const createCategory = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData();
    data.append("name", categoryFormData.name);
    data.append("description", categoryFormData.description);
    data.append("category_image", categoryFormData.thumbnail);
    try {
      const res = await api.createCategory(data);
      if (res) {
        setLoading(false);
        refreshState();
        handleClose();
        setCategoryFormData({
          name: "",
          description: "",
          thumbnail: {},
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  // const createCategoryAttribute = async (e) => {
  //   setLoading(true);
  //   e.preventDefault();
  //   try {
  //     const res = await api.addAttribute({
  //       name: attributeName,
  //       category_id: +categoryId,
  //     });
  //     if (res) {
  //       refreshState();
  //       setAttributeName("");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     if (error?.response?.data?.message) {
  //       toast.error(error?.response?.data?.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  // const removeAttribute = async (id) => {
  //   setLoading(true);
  //   try {
  //     const res = await api.removeAttribute(id);
  //     if (res) {
  //       refreshState();
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     if (error?.response?.data?.message) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const handleChange = (event) => {
    if (event.target.name === "thumbnail") {
      setCategoryFormData({
        ...categoryFormData,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setCategoryFormData({
        ...categoryFormData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const updateCate = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", categoryFormData.name);
    formData.append("description", categoryFormData.description);
    formData.append("category_image", categoryFormData.thumbnail);
    try {
      const res = await api.updateCategory(data.id, formData);
      if (res) {
        setCategoryFormData({
          name: "",
          description: "",
          thumbnail: {},
        });
        setLoading(false);
        refreshState();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {whatDoYouWant === "create" ? "Create Category" : "Edit Categories"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form className="flex flex-col gap-5 w-full">
              <div>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth={true}
                  value={categoryFormData.name}
                  size="small"
                  color="info"
                  required
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div>
                <TextField
                  id="description"
                  label="Description"
                  variant="outlined"
                  fullWidth={true}
                  value={categoryFormData.description}
                  size="small"
                  color="info"
                  required
                  name="description"
                  onChange={handleChange}
                />
              </div>
              <div>
                <Input
                  id="thumbnail"
                  name="thumbnail"
                  type="file"
                  disableUnderline={true}
                  fullWidth={true}
                  className="w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1"
                  onChange={handleChange}
                ></Input>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                !categoryFormData.name.length > 0 ||
                !categoryFormData.description.length > 0
                //   TODO: Add validation for Image
              }
              variant="contained"
              disableElevation
              onClick={(event) => {
                if (whatDoYouWant === "create") {
                  createCategory(event);
                } else {
                  updateCate(event);
                }
              }}
            >
              {whatDoYouWant === "create" ? "Submit" : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}

export default CreateCategory;
